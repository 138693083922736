import styles from "./Contact.module.css";

function ContactUs() {
  return (
    <div className={styles.ContactUs}>
      <h1>Contact us</h1>
      <p>You can reach us through:</p>
      <ul>
        <a href="mailto: elsan1874@gmail.com">
          <li>
            <i className="far fa-envelope" />{" "}
            <span>elsan1874@gmail.com</span>
          </li>
        </a>

        <a href="tel: +1 (971) 444 4158">
          <li>
            <i className="fas fa-phone" />
            <span> +1 (971) 444 4158</span>
          </li>
        </a>
      </ul>
    </div>
  );
}

export default ContactUs;
