import styles from "./About.module.css";
import image from "../../assets/images/break.png";
import ceo from "../../assets/images/ceo.jpeg";

function About() {
  return (
    <div id={styles.About}>
      <div className={styles.ceo}>
        <img src={ceo} alt="CEO" />
        <p style={{ fontWeight: "bold" }}>Elsa Nilsson</p>
        <p>
          <span>CEO</span>, Gustafsson Holdings
        </p>
      </div>
      <img src={image} alt="no-alt" />
      <h1>About us</h1>
      <p>
      At <span>Gustafsson holdings</span>, we see possibilities through innovation. We&apos;re partnering with our customers to fulfill healthcare&apos;s greatest potential through groundbreaking medical technology, intelligent devices, and care solutions. Better tools enabling better patient care. Together, we are not only building a healthier future but living our purpose to create a world where healthcare has no limits. Our innovations are driven by consumer and customer needs. Helping people to live healthily and prevent disease. Giving clinicians the tools they need to make a precision diagnosis and deliver personalized treatment. Aiding the patient's recovery at home in the community. All supported by a seamless flow of data.
      </p>
    </div>
  );
}

export default About;
